export const logisticRoutes = {
  path: "/logistic",
  meta: { requiresAuth: true },
  component: () => import("./Main"),
  children: [
    { path: "", component: () => import("./stock/Index") },
    {
      path: "inputs/:input_id",
      component: () => import("./inputs/FormPage"),
      props: true
    },
    { path: "inputs", component: () => import("./inputs/Index") },
    {
      path: "products",
      component: () => import("../store-module/products/Index")
    },
    // { path: 'outputs/:input_id', component: () => import('./outputs/FormPage'), props: true },
    { path: "outputs", component: () => import("./outputs/Index") },
    {
      path: "outputs/:output_id",
      component: () => import("./outputs/FormPage"),
      props: true
    },
    {
      path: "categories",
      component: () => import("../general-module/categories/Index")
    },
    { path: "suppliers", component: () => import("./suppliers/Index") },
    { path: "stores", component: () => import("./stores/Index") },
    { path: "stock", component: () => import("./stock/Index") },
    { path: "stock-config", component: () => import("./stock/Config") },
    {
      path: "brands",
      component: () => import("../general-module/brands/Index")
    },
    { path: "requirements", component: () => import("./requirements/Index") },
    {
      path: "requirements/:requirement_id",
      component: () => import("./requirements/FormPage"),
      props: true
    },
    {
      path: "reports/output-times",
      component: () => import("./reports/OutputTimes")
    },
    {
      path: "requirements/:requirement_id/quotation",
      component: () => import("./quotations/requirement.vue"),
      props: true
    }
  ]
};
