import { AdminService } from "./AdminService";

// let adminService = new AdminService();

export const adminStore = {
  namespaced: true,
  state: {
    areas: {
      list: []
    },
    entitys: {
      list: []
    },
    sources: {
      default: {}
    },
    change_rate: []
    // changeRate: []
  },
  mutations: {
    setAreaList(state, lista) {
      state.areas.list = lista;
    },
    setEntitysList(state, lista) {
      state.entitys.list = lista;
    },
    setSourceDefault(state, source) {
      state.sources.default = source;
    },
    setChangeRate(state, change) {
      state.change_rate = change;
    }
  },
  actions: {
    getConfig({ dispatch }) {
      dispatch("getAreas");
      dispatch("getEntitys");
      // dispatch('getSourceDefault')
      dispatch("getChangeRate");
    },

    // getAreas({ commit }) {
    //   // AdminService.getAreas().then(res => commit('setAreaList', res))
    // },

    getEntitys({ commit }) {
      AdminService.getEntitys().then(res => commit("setEntitysList", res));
    },

    getChangeRate({ commit }) {
      AdminService.getChangeRate().then(val => {
        commit("setChangeRate", val);
      });
    },

    getChangeRateTo({ state }, { money_code_ref, money_code }) {
      return new Promise(rsv => {
        let list = state.change_rate.filter(
          x => x.money_code_ref == money_code_ref && x.money_code == money_code
        );
        if (list.length > 0) {
          rsv(list[0].fixed_rate);
        }
        rsv(null);
      });
    }
  }
};
