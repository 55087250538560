import { StoreService } from "../store-module/service";

/** Comentado desde c9 */

export const dentalClinicStore = {
  namespaced: true,
  state: {
    client: {},
    odo_group: 1,
    quotes: 0
  },
  mutations: {
    setClient(state, client) {
      state.client = client;
    },
    setOdoGroup(state, val) {
      state.odo_group = val;
    },
    setQuotes(state, val) {
      state.quotes = val;
    }
  },
  actions: {
    // setSeeModuleMenu({comm})
    loadClientFromId({ commit }, client_id) {
      // debugger
      return new Promise(rsv => {
        StoreService.getClient(client_id).then(client => {
          commit("setClient", client);
          rsv();
        });
      });
    },

    reloadClient({ dispatch, state }) {
      return new Promise(rsv => {
        dispatch("loadClientFromId", state.client.id).then(() => {
          rsv();
        });
      });
    }
  }
};
