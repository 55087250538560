import { render, staticRenderFns } from "./TrLoading.vue?vue&type=template&id=680c7522&scoped=true&"
import script from "./TrLoading.vue?vue&type=script&lang=js&"
export * from "./TrLoading.vue?vue&type=script&lang=js&"
import style0 from "./TrLoading.vue?vue&type=style&index=0&id=680c7522&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680c7522",
  null
  
)

export default component.exports