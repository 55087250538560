// import http from "../http";
import { LogisticService } from "./service";

export const logisticStore = {
  namespaced: true,
  state: {
    stores: {}
  },
  mutations: {
    setStores(state, list) {
      state.stores = list;
    }
  },
  actions: {
    getStores({ commit }) {
      LogisticService.getStores({ my_stores: 1, from_my_entity: 1 }).then(res => {
        commit('setStores', res);
      });
    }
  }
}