export const storeRoutes = {
  path: "/store",
  meta: { requiresAuth: true },
  component: () => import("./Main"),
  children: [
    { path: "register-sale", component: () => import("./sales/Store") },
    { path: "clients", component: () => import("./clients/Index") },
    {
      path: "sales",
      component: () => import("./sales/Index"),
      name: "store-sales"
    },
    {
      path: "my-sales",
      component: () => import("./sales/Index"),
      props: { mySales: true, lastCashRegister: true }
    },
    { path: "mini-store", component: () => import("./sales/MiniStore") },
    {
      path: "products",
      component: () => import("./products/Index"),
      meta: { title: "PRODUCTOS" }
    },
    {
      path: "product-groups",
      component: () => import("./product-groups/Index.vue")
    },
    {
      path: "goods",
      component: () => import("./goods/IndexPage.vue")
    },
    {
      path: "product/:id",
      name: "store-product-show",
      component: () => import("./products/FormPage.vue")
    }
  ]
};
