import http, { getFromLocalStorage } from "../utils/http";

export class AdminService {
  constructor() {}

  static async getCurrentCashRegister() {
    return (await http.get("api/admin/cash-registers/current")).data;
  }

  static getEntityUtilities = async (params) =>
    (await http.get("api/admin/reports/entity-utilities", { params })).data;

  static getReportEmployeeLiquidations = async (params) =>
    (await http.get("api/general/reports/employee-liquidations", { params }))
      .data;

  static getReportLiquidations = async (params) =>
    (await http.get("api/admin/reports-liquidations", { params })).data;

  static getLiquidationOverloads = async (params) =>
    (await http.get("api/admin/liquidation-overloads", { params })).data;

  static getLiquidationOverload = async (id) =>
    (await http.get(`api/admin/liquidation-overloads/${id}`)).data;

  static saveLiquidationOverload = async (reg) => {
    if (reg.id)
      return (await http.put(`api/admin/liquidation-overloads/${reg.id}`, reg))
        .data;
    else return (await http.post("api/admin/liquidation-overloads", reg)).data;
  };

  static deleteLiquidationOverload = async (id) =>
    (await http.delete(`api/admin/liquidation-overloads/${id}`)).data;

  static getLiquidations = async (params) =>
    (await http.get("api/admin/liquidations", { params })).data;

  static getLiquidation = async (id) =>
    (await http.get(`api/admin/liquidations/${id}`)).data;

  static saveLiquidations = async (reg) => {
    if (reg.id)
      return (await http.put(`api/admin/liquidations/${reg.id}`, reg)).data;
    else return (await http.post("api/admin/liquidations", reg)).data;
  };

  static deleteLiquidations = async (id) =>
    (await http.delete(`api/admin/liquidations/${id}`)).data;

  static getEmployeeSpecialties = async (employee_id) => {
    return (
      await http.get("api/general/employee-specialties", {
        params: { employee_id },
      })
    ).data;
  };

  static saveSourceBudget = async (reg) => {
    return (await http.post("api/admin/source-budgets", reg)).data;
  };

  static deleteEmployeeSpecialtie = async (id) => {
    return (await http.delete("api/general/employee-specialties/" + id)).data;
  };

  static saveEmployeeSpecialty = async (reg) => {
    return (await http.post("api/general/employee-specialties", reg)).data;
  };

  static getIdMyCashRegister = async () => {
    return (await http.get("api/admin/id-my-cashregister")).data;
  };

  static getCashRegisters = async (params) => {
    return (await http.get("api/admin/cash-registers", { params })).data;
  };

  static saveCashRegisterInput = async (reg) => {
    if (reg.id) {
      return (await http.put(`api/admin/cash-register/inputs/${reg.id}`, reg))
        .data;
    } else {
      return (await http.post("api/admin/cash-register/inputs", reg)).data;
    }
  };

  static getReportFinantial = async (params) => {
    return (await http.get(`api/admin/reports/finantial`, { params })).data;
  };

  static getDayresults = async (params) => {
    return (await http.get(`api/admin/reports/dayresults`, { params })).data;
  };

  static getSources = async (params) => {
    return (await http.get(`api/admin/sources`, { params })).data;
  };

  static deleteSource = async (id) => {
    return (await http.delete(`api/admin/sources/${id}`)).data;
  };

  static saveReasonBudget = async (reg) => {
    if (reg.id) {
      return (await http.put(`api/admin/reason-budgets/${reg.id}`, reg)).data;
    } else {
      return (await http.post(`api/admin/reason-budgets`, reg)).data;
    }
  };

  static saveReason = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/admin/reasons", reg)).data;
    } else {
      return (await http.put("api/admin/reasons/" + reg.id, reg)).data;
    }
  };

  static deleteReason = async (id) => {
    return (await http.delete(`api/admin/reasons/${id}`)).data;
  };

  static saveCashRegisterOutput = async (reg) => {
    if (reg.id) {
      return (await http.put(`api/admin/cash-register/outputs/${reg.id}`, reg))
        .data;
    } else {
      return (await http.post(`api/admin/cash-register/outputs`, reg)).data;
    }
  };

  static saveArea = async (reg) => {
    if (reg.id) {
      return (await http.put("api/areas/" + reg.id, reg)).data;
    } else {
      return (await http.post("api/areas", reg)).data;
    }
  };

  static saveScheduleTime = async (reg) => {
    return (await http.post("api/general/schedule-times", reg)).data;
  };

  static deleteSourceGroup = async (id) => {
    return (await http.delete(`api/admin/source-groups/${id}`)).data;
  };

  static saveSourceGroup = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/admin/source-groups", reg)).data;
    }
  };

  static getSourceGroups = async (params) => {
    return (await http.get("api/admin/source-groups", { params })).data;
  };

  static getEntity = async (id) => {
    return (await http.get("api/entitys/" + id)).data;
  };

  static deleteEmployeeSchedule = async (id) => {
    return (await http.delete("api/general/employee-schedules/" + id)).data;
  };

  static getEmployeePayments = async (params) => {
    return (await http.get("api/general/employee-payments", { params })).data;
  };

  static getEmployeeSchedules = async (params) => {
    return (
      await http.get("api/general/employee-schedules", {
        params,
      })
    ).data;
  };

  // static getEmployeeSchedule = async employee_id => {
  //   return (await http.get("api/general/employee-schedules", {
  //     params: { employee_id }
  //   })).data;
  // };

  static saveEmployeeSchedule = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/general/employee-schedules", reg)).data;
    }
    return (await http.put("api/general/employee-schedules/" + reg.id, reg))
      .data;
  };

  static deleteEmployee = async (id) => {
    return await http.delete("api/general/employees/" + id);
  };

  static getEmployee = async (id) => {
    return (await getFromLocalStorage("api/general/employees/" + id)).data;
  };

  static saveEmployee = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/general/employees", reg)).data;
    } else {
      await http.put(`api/general/employees/${reg.id}`, reg);
      return reg.id;
    }
  };

  static getAreas = async (params, getFromServer = false) => {
    return (await getFromLocalStorage("api/areas", { params }, getFromServer))
      .data;
  };

  static getAllAreas = async () => {
    return this.getAreas(true);
  };

  static deleteInput = async (id) => {
    await http.delete("api/admin/inputs/" + id);
    return;
  };

  static deleteCROutput = async (cr_id, id) => {
    await http.delete("api/admin/cash-register/outputs/" + id, {
      params: { cr_id },
    });
    return;
  };

  static getChangeRate = async () => {
    return (await http.get("api/admin/exchange-rates/current")).data;
  };

  // static getAllEntitys = async () => {
  //   return this.getEntitys();
  // };

  static getEntitys = async (params, getFromServer = false) => {
    return (await getFromLocalStorage("api/entitys", { params }, getFromServer))
      .data;
    // let list;
    // try {
    //   list = JSON.parse(localStorage.getItem("entitys"));
    // } catch (error) {
    //   list = null;
    // }

    // if (!list) {
    //   list = (await http.get("api/entitys", { params: { a: 1 } })).data;
    //   localStorage.setItem("entitys", JSON.stringify(list));
    // }

    // return list.filter(x => x.enable == 1 || all);
  };

  // static getSources = async ({ area_id, search }) => {
  //   return (await http.get("api/admin/sources", {
  //     params: { search, area_id }
  //   })).data;
  // };

  static getReasons = async (params) => {
    return (await http.get("api/admin/reasons", { params })).data;
  };

  static getCashRegister = async (id) => {
    return (await http.get("api/admin/cash-registers/" + id)).data;
  };

  static getSource = async (source_id) => {
    return (await http.get("api/admin/sources/" + source_id)).data;
  };

  static getReason = async (reason_id) => {
    return (await http.get("api/admin/reasons/" + reason_id)).data;
  };

  static saveCashRegister = async (reg) => {
    if (reg.id) {
      return (await http.put("api/admin/cash-registers/" + reg.id, reg)).data;
    } else {
      return (await http.post("api/admin/cash-registers", reg)).data;
    }
  };

  static getUsers = async (search) => {
    let res = await http.get("api/general/users", {
      params: { search },
    });
    return res.data;
  };

  static getUser = async (user_id) => {
    let res = await http.get("api/general/users/" + user_id);
    return res.data;
  };

  static saveEntity(reg) {
    return new Promise((rsv) => {
      if (reg.id) {
        http.put("api/entitys/" + reg.id, reg).then(() => rsv());
      } else {
        http.post("api/entitys", reg).then(() => rsv());
      }
    });
  }

  static getExChangeRate = async () => {
    return (await http.get("api/admin/exchange-rates")).data;
  };

  static saveExChangeRate = async (reg) => {
    await http.post("api/admin/exchange-rates", reg);
    return;
  };

  static saveSource = async (reg) => {
    if (!reg.id) {
      await http.post("api/admin/sources", reg);
      return;
    } else {
      await http.put("api/admin/sources/" + reg.id, reg);
      return;
    }
  };

  static getEmployees = async (params) => {
    return (
      await http.get("api/general/employees", {
        params,
      })
    ).data;
  };

  static async reverseLiquidation(liquidationId) {
    return (
      await http.put(`api/admin/liquidations/${liquidationId}`, {
        reverse: 1,
      })
    ).data;
  }
}
