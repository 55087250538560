<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2022-07-17 20:25 -->
<!-- Description: -->
<template>
  <a class="btn btn-success" :href="routeToWsp" target="_black">
    <i class="fab fa-whatsapp"></i>
    <slot></slot>
  </a>
</template>
<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    number: {},
    message: {}
  },

  data: () => ({
    //
  }),

  computed: {
    routeToWsp() {
      // https://api.whatsapp.com/send?phone=51935586867&text=Hola%20esto%20es%20un%20saludo
      return (
        `https://api.whatsapp.com/send?phone=${this.number}&text=` +
        encodeURIComponent(this.message)
      );
    }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    //
    //
    //
  }
};
</script>

<style scoped></style>
