<template>
  <router-view></router-view>
</template>
<script>
// import NavLeft from "./NavLeft";
// import NavBar from "./NavBar";
import { mapState } from "vuex";

export default {
  components: {
    // NavLeft,
    // NavBar
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    seeNavLeft: false
  }),

  computed: {
    ...mapState({
      config: (s) => s.config.dental.proform.days_to_init
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    handleToggleNavLeft() {
      this.seeNavLeft = !this.seeNavLeft;
    }
  }
};
</script>

<style></style>
