import http from "../utils/http";
import validate from "validate.js";
// import { throwError } from "rxjs";

export class StoreService {
  // static saveNewSale = async(reg) => {
  //   return (await http.post("api/store/sale", reg)).data;
  // }

  static getClientAlerts = async params =>
    (await http.get("api/store/client-alerts", { params })).data;

  static getClientAlert = async id =>
    (await http.get(`api/store/client-alerts /${id}`)).data;

  static saveClientAlert = async reg => {
    if (reg.id)
      return (await http.put(`api/store/client-alerts/${reg.id}`, reg)).data;
    else return (await http.post("api/store/client-alerts", reg)).data;
  };

  static deleteClientAlert = async id =>
    (await http.delete(`api/store/client-alerts /${id}`)).data;

  static deleteClient = async id => {
    return (await http.delete(`api/store/clients/${id}`)).data;
  };

  static deleteSale = async id => {
    return (await http.delete(`api/store/sales/${id}`)).data;
  };

  static getSales = async params => {
    return (await http.get("api/store/sales", { params })).data;
  };

  static getClients = async params => {
    return (
      await http.get("api/store/clients", {
        params
      })
    ).data;
  };

  static getClient = async id => {
    return (await http.get("api/store/clients/" + id)).data;
  };

  static saveClient = async reg => {
    if (!reg.id) {
      return (await http.post("api/store/clients", reg)).data;
    } else {
      return (await http.put("api/store/clients/" + reg.id, reg)).data;
    }
  };

  static getProducts = async params =>
    (await http.get("api/store/products", { params })).data;
  static getProduct = async id =>
    (await http.get(`api/store/products/${id}`)).data;
  static saveProduct = async (reg, config) => {
    //  console.log("hola", reg);
    if (reg.has("id"))
      return (await http.post(`api/store/products/${reg.get('id')}`, reg, config))
        .data;
    else return (await http.post("api/store/products", reg, config)).data;
  };
  static deleteProduct = async id =>
    (await http.delete(`api/store/products/${id}`)).data;

  // static getProducts = async params => {
  //   return (await http.get("api/store/products", { params })).data;
  // };

  // static getProduct = async id => {
  //   return (await http.get(`api/store/products/${id}`)).data;
  // };

  // static getProducts = async params => {
  //   return (await http.get("api/store/products", { params: params })).data;
  // };

  static saveSale = async saleRequest => {
    const rules = {
      pay: { presence: true },
      pay_change: { presence: true },
      payment_method_id: { presence: true },
      pay_money_code: { presence: true }
    };

    const productRules = {
      product_id: { presence: true },
      quantity: { presence: true },
      unit_price: { presence: true },
      money_code: { presence: true }
    };

    const goToError = function(args) {
      throw TypeError("No mames wey :/", args);
    };

    let errorsList = validate(saleRequest, rules);

    if (!errorsList) {
      for (let ps of saleRequest.products) {
        let tErr = validate(ps, productRules);
        if (tErr) {
          goToError(tErr);
        }
      }
      return (await http.post("api/store/sales", saleRequest)).data;
    } else {
      goToError(errorsList);
    }
  };
}
