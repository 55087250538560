import { render, staticRenderFns } from "./ButtonSubmit.vue?vue&type=template&id=110ef44c&scoped=true&"
import script from "./ButtonSubmit.vue?vue&type=script&lang=js&"
export * from "./ButtonSubmit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110ef44c",
  null
  
)

export default component.exports