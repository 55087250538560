<template>
  <Multiselect
    v-model="val"
    :options="options"
    :placeholder="placeholder"
    selectLabel="seleccionar"
    deselectLabel="deseleccionar"
    :loading="isLoading"
    @search-change="find"
    :internal-search="false"
    @open="openHandler"
    :disabled="disabled"
  >
    <template slot="singleLabel" slot-scope="props">{{
      props.option.name
    }}</template>
    <template slot="option" slot-scope="props">{{
      props.option.name
    }}</template>
    <span slot="noOptions">Sin resultados</span>
    <span slot="noResult">No hay resultados</span>
  </Multiselect>
</template>

<script>
import { debounce } from "lodash";
import { AdminService } from "../AdminService";

export default {
  props: {
    placeholder: {
      default: "Seleccionar"
    },
    value: {
      default: undefined
    },
    autoSet: {
      default: false
    },
    disabled: {
      default: false
    },
    specialties: {
      default: ""
    }
  },
  watch: {
    val(to) {
      if (to == null || to.id == undefined) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", to.id);
      }
    }
  },
  data() {
    return {
      isLoading: false,
      val: undefined,
      options: []
    };
  },
  methods: {
    reset() {
      this.val = null;
      this.$emit("input", null);
    },
    setValueFromId(reg_id) {
      return new Promise((rsv) => {
        if (reg_id) {
          this.isLoading = true;
          // http.get("api/general/employees/" + reg_id)
          AdminService.getEmployee(reg_id).then((res) => {
            this.isLoading = false;
            this.options = [res];
            this.val = res;
            if (this.value != reg_id) this.$emit("input", reg_id);
            rsv({ data: res });
          });
        } else {
          this.val = {};
          rsv(null);
        }
      });
    },
    openHandler() {
      this.getList();
      this.$emit("open");
    },
    getList(search) {
      return new Promise(() => {
        // http
        //   .get("api/general/employees", {
        //     params: { search, specialties: this.specialties }
        //   })
        AdminService.getEmployees({
          search,
          specialties: this.specialties
        }).then((res) => {
          this.options = res.data;
          this.isLoading = false;

          if (this.autoSet && this.options.length == 1) {
            this.val = this.options[0];
            this.$emit("input", this.val.id);
          }
        });
      });
    },
    setDefault() {},
    find(search) {
      if (search) this.asyncFind(search, this);
    },
    asyncFind: debounce((search, vm) => {
      vm.isLoading = true;
      vm.getList(search);
    }, 350)
  }
};
</script>

<style>
.multiselect__placeholder {
  margin-bottom: 0px;
}
</style>
