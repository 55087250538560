<template>
  <div>
    <div
      class="bg-light mb-2 text-center"
      v-show="total > 0 && total > items.length"
    >
      <a href="#" @click.prevent="getMoreComments()" class="dropdown-toggle">
        Cargar mas comentarios
      </a>
    </div>
    <div class="bg-light mb-2">
      <!-- <div class="btn-block btn btn-sm rounded border bg-white p-1 pl-2 mb-1 mr-1"
        [hidden]="items.length < _nComentariosVisibles || nComentariosVisibles == 0"
        (click)="nComentariosVisibles = 0; mostrarComentarios()">
        ver mas comentarios <small>({{nComentariosVisibles}} comentarios visibles)</small>
      </div>-->
      <div
        class="rounded border bg-white p-1 pl-2 mb-1 mr-1"
        v-for="(l, i) in items"
        :key="i"
      >
        <!-- <app-span-usuario class="mr-2" idUsuario="l.idUser"></app-span-usuario> -->
        <span class="badge badge-primary">{{ l.user_name }}</span>
        <span class="badge badge-secondary mr-1">
          {{ l.created_at | date }}
        </span>
        <small :title="l.created_at">{{ tiempoRelativo(l.created_at) }}</small>

        <br />
        {{ l.comment }}
      </div>
    </div>

    <!-- <app-textarea v-model="comment"></app-textarea> -->
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        v-model="comment"
        maxlength="255"
        placeholder="comentario"
        @keyup.enter="addComment()"
      />
      <div class="input-group-append">
        <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
        <button class="btn btn-light input-group-text" @click="addComment()">
          <i class="fa fa-save"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { myMoment } from "../../utils/myMoment";
import { mapState } from "vuex";
import http from "../../utils/http";

export default {
  components: {
    //
  },

  //directives
  //filters

  props: {
    url: {},
    disabled: {
      default: false
    }
  },

  data: () => ({
    comment: "",
    items: [],
    page: 1,
    total: 0
  }),

  computed: {
    ...mapState({
      user: s => s.user
    })
  },

  watch: {
    url: {
      immediate: true,
      handler() {
        this.items = [];
        this.getComments();
      }
    }
  },

  mounted() {
    //
  },

  methods: {
    getMoreComments() {
      this.page++;
      this.getComments(false);
    },
    getComments(clearList = true) {
      if (clearList) {
        // this.items = [];
        this.page = 1;
        this.total = 0;
      }
      http
        .get("api/comments", {
          params: {
            url: this.url,
            page: this.page,
            per_page: 3
          }
        })
        .then(({ data }) => {
          this.total = data.total;
          for (let it of data.data) {
            this.items.unshift(it);
          }
        });
    },
    addComment() {
      let reg = {
        // id: res,
        user_name: this.user.name,
        comment: this.comment,
        url: this.url,
        created_at: myMoment().format("YYYY-MM-DD HH:mm")
      };
      this.items.push(reg);
      this.comment = null;

      http.post("api/comments", reg).then(res => {
        reg.id = res;
      });
    },
    tiempoRelativo(time) {
      return myMoment(time).fromNow();
    }
  }
};
</script>

<style></style>
