<template>
  <select class="custom-select" v-model="valueChild">
    <option v-for="(l, index) in config.list" :value="index" :key="index"
      >{{ l.value }}
    </option>
  </select>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    value: {}
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({
      config: s => s.config.payment_method || {}
    }),
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    setDefault() {
      this.$emit("input", this.config.default);
    }
  }
};
</script>

<style></style>
