import http from "../utils/http.js";
// import validate from "validate.js";
// import { throwError } from "rxjs";

export class StoreService {
  static async getClientAddresses(params) {
    return (await http.get("api/store/client-address", { params })).data;
  }

  static async getClientAddress(id) {
    return (await http.get(`api/store/client-address/${id}`)).data;
  }

  static async saveClientAddress(reg) {
    if (reg.id === undefined) {
      return (await http.post("api/store/client-address", reg)).data;
    } else {
      return (await http.put(`api/store/client-address/${reg.id}`, reg)).data;
    }
  }

  static async deleteClientAddress(id) {
    return (await http.delete(`api/store/client-address/${id}`)).data;
  }

  static getClientAlerts = async (params) =>
    (await http.get("api/store/client-alerts", { params })).data;

  static getClientAlert = async (id) =>
    (await http.get(`api/store/client-alerts /${id}`)).data;

  static saveClientAlert = async (reg) => {
    if (reg.id)
      return (await http.put(`api/store/client-alerts/${reg.id}`, reg)).data;
    else return (await http.post("api/store/client-alerts", reg)).data;
  };

  static deleteClientAlert = async (id) =>
    (await http.delete(`api/store/client-alerts /${id}`)).data;

  static deleteClient = async (id) => {
    return (await http.delete(`api/store/clients/${id}`)).data;
  };

  static getClients = async (params) => {
    return (
      await http.get("api/store/clients", {
        params
      })
    ).data;
  };

  static getClient = async (id) => {
    return (await http.get("api/store/clients/" + id)).data;
  };

  static saveClient = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/store/clients", reg)).data;
    } else {
      return (await http.put("api/store/clients/" + reg.id, reg)).data;
    }
  };

  static getProducts = async (params) =>
    (await http.get("api/store/products", { params })).data;
  static getProduct = async (id) =>
    (await http.get(`api/store/products/${id}`)).data;

  static async saveProduct(reg) {
    if (reg.id) {
      return (await http.put(`api/store/products/${reg.id}`, reg)).data;
    } else {
      return (await http.post("api/store/products", reg)).data;
    }
  }

  static deleteProduct = async (id) =>
    (await http.delete(`api/store/products/${id}`)).data;

  // static getProducts = async params => {
  //   return (await http.get("api/store/products", { params })).data;
  // };

  // static getProduct = async id => {
  //   return (await http.get(`api/store/products/${id}`)).data;
  // };

  // static getProducts = async params => {
  //   return (await http.get("api/store/products", { params: params })).data;
  // };
  //
  static async getSales(params) {
    return (await http.get("api/store/sales", { params })).data;
  }

  static async getSale(id) {
    return (await http.get(`api/store/sales/${id}`)).data;
  }

  static async saveSale(reg) {
    if (reg.id === undefined || reg.id === null) {
      return (await http.post("api/store/sales", reg)).data;
    } else {
      return (await http.put(`api/store/sales/${reg.id}`, reg)).data;
    }
  }

  static async deleteSale(id) {
    return (await http.delete(`api/store/sales/${id}`)).data;
  }

  // static saveSale = async (saleRequest) => {
  //   const rules = {
  //     pay: { presence: true }
  //   };

  //   const productRules = {
  //     id: { presence: true },
  //     quantity: { presence: true },
  //     unit_price: { presence: true }
  //   };

  //   const goToError = function (args) {
  //     throw TypeError("Error en validacion previo envio", args);
  //   };

  //   let errorsList = validate(saleRequest, rules);

  //   if (!errorsList) {
  //     for (let ps of saleRequest.products) {
  //       let tErr = validate(ps, productRules);
  //       if (tErr) {
  //         goToError(tErr);
  //       }
  //     }
  //     return (await http.post("api/store/sales", saleRequest)).data;
  //   } else {
  //     goToError(errorsList);
  //   }
  // };

  static async getProductGroups(params) {
    return (await http.get("api/store/product-groups", { params })).data;
  }

  static async getProductGroup(id) {
    return (await http.get(`api/store/product-groups/${id}`)).data;
  }

  static async saveProductGroup(reg) {
    if (reg.id === undefined) {
      return (await http.post("api/store/product-groups", reg)).data;
    } else {
      return (await http.put(`api/store/product-groups/${reg.id}`, reg)).data;
    }
  }

  static async deleteProductGroup(id) {
    return (await http.delete(`api/store/product-groups/${id}`)).data;
  }

  static async getProductSales(params) {
    return (await http.get("api/store/product-sales", { params })).data;
  }

  static async getProductSale(id) {
    return (await http.get(`api/store/product-sales/${id}`)).data;
  }

  static async saveProductSale(reg) {
    if (reg.id === undefined || reg.id === null) {
      return (await http.post("api/store/product-sales", reg)).data;
    } else {
      return (await http.put(`api/store/product-sales/${reg.id}`, reg)).data;
    }
  }

  static async deleteProductSale(id) {
    return (await http.delete(`api/store/product-sales/${id}`)).data;
  }
}
