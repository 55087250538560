import Vue from "vue";
import Vuex from "vuex";
import { authStore } from "../auth-module";
import http from "../utils/http";
import { adminStore } from "../admin-module";
import { logisticStore } from "../logistic-module";
import { displayStore } from "../utils/displayStore";
import { dentalClinicStore } from "../dental-clinic-module/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {}, // usuario autentificado
    config: {},
    permissions: []
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.permissions = user.permissions;
    },
    setConfig(state, newConfig) {
      state.config = newConfig;
    }
  },
  actions: {
    getUser({ commit }) {
      return new Promise((rsv, err) => {
        http.get("api/user").then(
          (res) => {
            commit("setConfig", res.data.config);
            delete res.data.config;
            commit("setUser", res.data);
            rsv();
          },
          () => err()
        );
      });
    },

    authAfterLogged({ dispatch }) {
      return new Promise((rsv) => {
        Promise.all([dispatch("getUser")])
          .then(
            () => {
              rsv();
            },
            () => {
              dispatch("auth/logout");
            }
          )
          .then(() => {
            rsv();
          });
      });
    },

    beforeInstance({ dispatch }) {
      return dispatch("authAfterLogged");
    }
  },
  modules: {
    auth: authStore(http),
    admin: adminStore,
    logistic: logisticStore,
    display: displayStore,
    dental: dentalClinicStore
  }
});
