import $ from "jquery";

export const displayStore = {
  namespaced: true,
  state: {
    sm: false // movil
  },
  mutations: {
    setSm(state, val) {
      state.sm = val;
    }
  },
  actions: {
    setSm({ commit, state }) {
      if ($(window).width() < 576) {
        if (!state.sm) commit("setSm", true);
      } else {
        if (state.sm) commit("setSm", false);
      }
    }
  }
};
