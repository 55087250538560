export const adminRoutes = {
  path: "/admin",
  meta: { requiresAuth: true },
  component: () => import("./Main"),
  children: [
    // { path: "", component: () => import("./cashregisters/MyBox") },
    { path: "entitys", component: () => import("./entitys/Index") },
    { path: "areas", component: () => import("./areas/Index") },
    { path: "employees", component: () => import("./employees/Index") },
    { path: "sources", component: () => import("./sources/Index") },
    { path: "reasons", component: () => import("./reasons/Index") },
    {
      path: "cashregisters",
      component: () => import("./cashregisters/Index.vue")
    },
    {
      path: "my-cashregister",
      component: () => import("./cashregisters/MyBox")
    },
    {
      path: "reports/finantial",
      component: () => import("./reports/Finantial")
    },
    {
      path: "reports/results-daily",
      component: () => import("./reports/ResultsDaily")
    },
    {
      path: "reports/liquidations",
      component: () => import("./reports/Liquidations")
    },
    {
      path: "reports/entitys",
      component: () => import("./reports/ReportEntitys")
    },
    {
      path: "cashregisters/:cashRegisterId",
      component: () => import("./cashregisters/Box"),
      props: true
    },

    { path: "liquidations", component: () => import("./liquidations/Index") }
  ]
};
