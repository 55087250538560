<template>
  <Multiselect
    v-model="val"
    :options="options"
    placeholder="Selecciona Uno"
    selectLabel="seleccionar"
    deselectLabel="deseleccionar"
    selectedLabel="seleccionado"
    :loading="isLoading"
    @search-change="find"
    :internal-search="false"
    @open="openHandler"
    :disabled="disabled"
    @select="$emit('select', $event)"
  >
    <template slot="singleLabel" slot-scope="{ option }">
      <!-- <strong>{{ props.option.code }}</strong> -->
      {{ option.name }}
    </template>
    <template slot="option" slot-scope="{ option }">
      <div class="media">
        <!-- <img -->
        <!--   class="mr-2" -->
        <!--   style="width: 3rem" -->
        <!--   :src="option.file_id | imageUrl" -->
        <!-- /> -->

        <div class="media-body">
          <span style="white-space: normal">
            <strong>{{ option.name }}</strong>
          </span>
          <div>
            <span class="badge badge-secondary mr-1">
              {{ option.categorie_name }}
            </span>
            <span
              v-show="seeBrand && option.brand_name"
              class="badge badge-primary mr-1"
              >{{ option.brand_name }}
            </span>
            <span v-show="option.code">{{ option.code }}</span>
          </div>
          <div>{{ option.source_name }}</div>
          <div v-show="option.quantity">CANTIDAD: {{ option.quantity }}</div>
        </div>
      </div>
    </template>
    <span slot="noOptions">Sin resultados</span>
    <span slot="noResult">No hay resultados</span>
  </Multiselect>
</template>

<script>
import { debounce } from "lodash";
import { StoreService } from "../service";
import { Multiselect } from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      default: undefined
    },
    autoSet: {
      default: false
    },
    typeId: {
      default: undefined
    },
    categorieId: {},
    disabled: {
      default: false
    },
    store_id: {
      default: undefined
    },
    area_id: {
      default: null
    },
    seeBrand: {
      default: true
    }
  },
  watch: {
    val(to) {
      if (to == null || to.id == undefined) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", to.id);
      }
    },
    categorieId() {
      this.getList();
    }
  },
  data() {
    return {
      isLoading: false,
      val: undefined,
      options: []
    };
  },
  computed: {
    urlToImage() {
      return process.env.VUE_APP_API_URL + "/files/";
    }
  },
  methods: {
    reset() {
      this.options = [];
      this.val = null;
      this.$emit("input", null);
    },
    async setValueFromId(reg_id) {
      if (reg_id && reg_id != (this.val && this.val.id)) {
        this.isLoading = true;
        let res = await StoreService.getProduct(reg_id);
        this.isLoading = false;
        this.options = [res];
        this.val = res;
        this.$emit("input", reg_id);
      }
    },
    openHandler() {
      if (this.options.length == 0) {
        this.getList();
      }
    },
    getList(search) {
      this.isLoading = true;
      StoreService.getProducts({
        search,
        type_id: this.typeId,
        categorie_id: this.categorieId,
        store_id: this.store_id,
        area_id: this.area_id
      }).then((res) => {
        this.options = res.data;
        this.isLoading = false;

        if (this.autoSet && this.options.length == 1) {
          this.val = this.options[0];
          this.$emit("input", this.val.id);
        }
      });
    },
    setDefault() {},
    find(search) {
      if (search) this.asyncFind(search, this);
    },
    asyncFind: debounce((search, vm) => {
      vm.getList(search);
    }, 350)
  }
};
</script>
<style></style>
