import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Multiselect from "vue-multiselect";
import VueSimpleSuggest from "vue-simple-suggest";
import { BaseModule } from "src/lt/base-module";
import "bootstrap";
import selectPaymentMethod from "./general-module/SelectPaymentMethod.vue";
import CommentsComponent from "./general-module/comments/Comments";

Vue.config.productionTip = false;

Vue.component("Multiselect", Multiselect);
Vue.component("VueSimpleSuggest", VueSimpleSuggest);

Vue.component("app-select-payment-method", selectPaymentMethod);
// Vue.component("app-spm", selectPaymentMethod);
Vue.component("app-comments", CommentsComponent);

Vue.use(BaseModule, {
  locale: "es",
  defaultCurrency: 1,
  currencyList: {
    1: { name: "SOLES", symbol: "S/", code: "PEN" },
    2: { name: "DOLARES", symbol: "$", code: "USD" }
  }
});

import "./deleteServiceWorkers.js";

store.dispatch("beforeInstance").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app");
});
