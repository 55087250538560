import http from "../utils/http";

export class LogisticService {
  static deleteRequirementCategorie = async id => {
    return (await http.delete(`api/logistic/requirement-categories/${id}`))
      .data;
  };

  static deleteRequirementDetails = async (aListIds = []) => {
    return (
      await http.post("api/logistic/requirement-details", {
        to_delete: 1,
        ids: aListIds
      })
    ).data;
  };

  static saveRequirementQuantity = async reg => {
    return LogisticService.saveRequirementDetail({
      setQuantitySupplier: 1,
      ...reg
    });
  };

  static addRequirementSupplier = async reg => {
    return LogisticService.saveRequirement({
      add_supplier: 1,
      ...reg
    });
  };

  static transferOutput = async output_id => {
    return (
      await http.put("api/logistic/outputs/" + output_id, {
        transfer: 1
      })
    ).data;
  };

  static addRequirementCategorie = async reg => {
    return (await http.post("api/logistic/requirement-categories", reg)).data;
  };

  static getRequirementCategories = async id => {
    return (
      await http.get("api/logistic/requirement-categories", {
        params: { requirement_id: id }
      })
    ).data;
  };

  static getSupplier = async id => {
    return (await http.get(`api/logistic/suppliers/${id}`)).data;
  };

  static saveSupplier = async reg => {
    if (!reg.id) {
      return (await http.post("api/logistic/suppliers", reg)).data;
    } else {
      return (await http.put("api/logistic/suppliers/" + reg.id, reg)).data;
    }
  };

  // static provideRequirement = async(requirement_id) => {
  //   return (await http.put("api/logistic/requirements/" + requirement_id, {letsProvide: 1})).data;
  // }

  static useStock = async reg => {
    return (await http.post("api/logistic/stock/use", reg)).data;
  };

  static getOutputTimes = async params => {
    return (await http.get("api/logistic/reports/output-times", { params }))
      .data;
  };

  static getRequirements = async params => {
    return (await http.get("api/logistic/requirements", { params })).data;
  };

  static saveRequirementDetail = async reg => {
    if (!reg.id) {
      return (await http.post("api/logistic/requirement-details", reg)).data;
    } else {
      return (await http.put("api/logistic/requirement-details/" + reg.id, reg))
        .data;
    }
  };

  static deleteRequirementDetail = async id => {
    return (await http.delete("api/logistic/requirement-details/" + id)).data;
  };

  static getRequirementDetails = async params => {
    return (await http.get("api/logistic/requirement-details", { params }))
      .data;
  };

  static saveInput = async reg => {
    if (!reg.id) {
      return (await http.post("api/logistic/inputs", reg)).data;
    } else {
      return (await http.put(`api/logistic/inputs/${reg.id}`, reg)).data;
    }
  };

  static saveInputDetail = async reg => {
    return (await http.post("api/logistic/input-details", reg)).data;
  };

  static saveRequirement = async reg => {
    if (!reg.id) {
      return (await http.post("api/logistic/requirements", reg)).data;
    } else {
      return (await http.put("api/logistic/requirements/" + reg.id, reg)).data;
    }
  };

  static getRequirement = async id => {
    return (await http.get("api/logistic/requirements/" + id)).data;
  };

  static getSuppliers = async params => {
    return (await http.get("api/logistic/suppliers", { params })).data;
  };

  static deleteOutputDetail = async id => {
    return (await http.delete("api/logistic/output-details/" + id)).data;
  };

  static getOutput = async id => {
    return (await http.get("api/logistic/outputs/" + id)).data;
  };

  static saveStockConfig = async reg => {
    if (!reg.id) {
      return (await http.post("api/logistic/stock", reg)).data;
    } else return (await http.put("api/logistic/stock/" + reg.id, reg)).data;
  };

  static getProductStock = async params => {
    return (await http.get("api/logistic/product-stock", { params })).data;
  };

  static saveSupplierProduct = async reg => {
    if (!reg.id) {
      return (await http.post("api/logistic/supplier-products", reg)).data;
    } else {
      return (await http.put("api/logistic/supplier-products/" + reg.id, reg))
        .data;
    }
  };

  static getSupplierProducts = async params => {
    return (await http.get("api/logistic/supplier-products", { params })).data;
  };

  static saveReconfigStock = async reg => {
    return (await http.post("api/logistic/stock/reconfig", reg)).data;
  };

  static getStockConfig = async params => {
    return (await http.get("api/logistic/stock", { params })).data;
  };

  static saveStore = async reg => {
    if (!reg.id) {
      return (await http.post("api/logistic/stores", reg)).data;
    } else {
      return (await http.put("api/logistic/stores/" + reg.id, reg)).data;
    }
  };

  static deleteStore = async reg => {
    return (await http.delete("api/logistic/stores/" + reg)).data;
  };

  static addStock = async reg => {
    if (!reg.id) {
      return (await http.post("api/logistic/stock/add-stock", reg)).data;
    }
  };

  static getStore = async id =>
    (await http.get(`api/logistic/stores/${id}`)).data;

  static getStores = async params => {
    return (await http.get("api/logistic/stores", { params })).data;
  };

  static saveOutput = async reg => {
    if (!reg.id) return (await http.post("api/logistic/outputs", reg)).data;
    else return (await http.put(`api/logistic/outputs/${reg.id}`, reg)).data;
  };

  static deleteInput = async id =>
    (await http.delete(`api/logistic/input-details/${id}`)).data;

  static getInput = async id =>
    (await http.get(`api/logistic/inputs/${id}`)).data;

  static getInputs = async params => {
    return (await http.get("api/logistic/inputs", { params })).data;
  };

  static getInputDetails = async params => {
    return (await http.get("api/logistic/input-details", { params })).data;
  };

  static getOutputs = async params => {
    return (await http.get("api/logistic/outputs", { params })).data;
  };

  static getOutputDetails = async params => {
    return (await http.get("api/logistic/output-details", { params })).data;
  };

  static getRucData = async ruc => {
    return (await http.get("api/logistic/ruc/" + ruc)).data;
  };

  static saveOutputDetail = async reg => {
    if (!reg.id) {
      return (await http.post("api/logistic/output-details", reg)).data;
    }
  };

  // supplier-payments
  static getSupplierPayments = async params =>
    (await http.get("api/logistic/supplier-payments", { params })).data;
  static getSupplierPayment = async id =>
    (await http.get(`api/logistic/supplier-payments/${id}`)).data;
  static saveSupplierPayments = async reg => {
    if (reg.id)
      return (await http.put(`api/logistic/supplier-payments/${reg.id}`, reg))
        .data;
    else return (await http.post("api/logistic/supplier-payments", reg)).data;
  };
  static deleteSupplierPayments = async id =>
    (await http.delete(`api/logistic/supplier-payments/${id}`)).data;
}
