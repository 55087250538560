/**
 *
 * Instancia de axios pre configurado
 *
 */

import axios from "axios";
import * as buildUrl from "axios/lib/helpers/buildURL";
import {
  secureStorage,
  secureSessionStorage
} from "../auth-module/secureStorage";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: secureStorage.getItem("token")
  }
});

export const getFromLocalStorage = function(
  url = "",
  config = {},
  getFromServer = false
) {
  url = buildUrl(url, config.params);
  var stringValue = secureStorage.getItem(url);
  if (!getFromServer && stringValue) {
    return new Promise(rsv => {
      rsv({ data: JSON.parse(stringValue) });
    });
  } else {
    return new Promise(rsv => {
      http.get(url).then(res => {
        secureStorage.setItem(url, JSON.stringify(res.data));
        rsv(res);
      });
    });
  }
};

export const getFromSessionStorage = function(
  url = "",
  config = {},
  getFromServer = false
) {
  url = buildUrl(url, config.params);
  var stringValue = secureSessionStorage.getItem(url);
  if (!getFromServer && stringValue) {
    return new Promise(rsv => {
      rsv({ data: JSON.parse(stringValue) });
    });
  } else {
    return new Promise(rsv => {
      http.get(url).then(res => {
        secureSessionStorage.setItem(url, JSON.stringify(res.data));
        rsv(res);
      });
    });
  }
};

export default http;
